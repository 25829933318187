var $ = require('jquery');
global.$ = $;
global.jquery = $;
global.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

global.Howler = require('howler/dist/howler');
require('bootstrap-slider');
global.io = require('socket.io-client');
global.Tagify = require('@yaireo/tagify/dist/tagify.js');

window._internalPageReady = function () {
    try { globalReady(); } catch (e) { console.log('globalReady()', e); }
    try { pageReady(); } catch (e) { console.log('pageReady()', e); }
    for (var i = 0; i < window.pageReadyHook.length; i++) {
        window.pageReadyHook[i]();
    }
    window.pageReadyHook = [];
}

global.imageUploaded = function(id, filename) {
    new bootstrap.Modal(document.getElementById('imageUploadModal'+id), {
        keyboard: false
    }).hide();
    $(document).trigger('imageUpload', id, filename);
}

global.globalReady = function() {
    if (typeof SPA != "undefined") {
        if (!window.spa) {
            window.spa = new SPA();
        }
        window.spa.connectActions('body');
    }
}

window._internalPageReady();

import './styles/global.scss';
